import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import FilterPlateFormContext from 'contexts/FilterPlateFormContext';
import toSentenceCase from '../../../services/toSentenceCase';
import useEditingDrillDown from './useEditingDrillDown';
import FilterPlatesContext from '../../../contexts/FilterPlatesContext';
import getWildcardFilterMode from '../../FilterPlates/FilterPlate/getWildcardFilterMode';

const useTextFilterMode = () => {
  const { editingTextDrillDown, editingWildcardDrillDown } =
    useEditingDrillDown();
  const { drillDown, field } = useContext(FilterPlateFormContext);
  const { textFieldModeGreenList } = useContext(FilterPlatesContext);
  const getTextFilterMode = useCallback((): TextFilterMode | undefined => {
    if (!drillDown) {
      return 'is one of';
    }

    if (editingTextDrillDown) {
      if (editingTextDrillDown.isExclude) {
        return 'is not one of';
      }
      return 'is one of';
    } else if (editingWildcardDrillDown) {
      return getWildcardFilterMode(editingWildcardDrillDown);
    }

    return undefined;
  }, [drillDown, editingTextDrillDown, editingWildcardDrillDown]);
  const [mode, setMode] = useState<TextFilterMode | undefined>(() =>
    getTextFilterMode(),
  );
  useEffect(() => {
    setMode(getTextFilterMode());
  }, [getTextFilterMode]);

  const permittedModes = useMemo((): TextFilterMode[] => {
    if (field && textFieldModeGreenList) {
      const greenList = textFieldModeGreenList[field];
      if (greenList) {
        return greenList;
      }
    }

    const modes: TextFilterMode[] = [
      'is one of',
      'is not one of',
      'contains',
      'does not contain',
      'starts with',
      'does not start with',
      'ends with',
      'does not end with',
    ];
    return modes;
  }, [field, textFieldModeGreenList]);

  const getModeOptions = useCallback((): DropdownOption[] => {
    return permittedModes.map((m) => ({
      key: m,
      label: toSentenceCase(m, true),
      isSelected: m === mode,
      onSelected: () => {
        setMode(m);
      },
    }));
  }, [mode, permittedModes]);

  const [modeOptions, setModeOptions] = useState<DropdownOption[]>(() =>
    getModeOptions(),
  );
  useEffect(() => {
    setModeOptions(getModeOptions());
  }, [getModeOptions]);

  return { mode, modeOptions };
};

export default useTextFilterMode;
