const getWildcardFilterMode = (drillDown: WildcardFilter) => {
  const { pattern, exclude } = drillDown.wildcardFilter;
  if (pattern.length === 0) {
    return undefined;
  }

  const isExcludes = exclude;

  const isEndsWith = pattern[0] === '*';
  const isStartsWith = pattern[pattern.length - 1] === '*';
  const isContains = isEndsWith && isEndsWith && pattern.split('*').length > 2;

  if (isContains) {
    return isExcludes ? 'does not contain' : 'contains';
  } else if (isStartsWith) {
    return isExcludes ? 'does not start with' : 'starts with';
  } else if (isEndsWith) {
    return isExcludes ? 'does not end with' : 'ends with';
  } else {
    return undefined;
  }
};

export default getWildcardFilterMode;
